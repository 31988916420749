import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import masterchefABI from 'config/abi/masterchef.json'
import prv2MasterChefABI from 'config/abi/prv2masterchef.json'
import prvgMasterChefABI from 'config/abi/prvgmasterchef.json'

import multicall,{getMasterChef} from 'utils/multicall'
import farmsConfig from 'config/constants/farms'
import contracts from 'config/constants/contracts'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchFarmUserAllowances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return { address: lpContractAddress, name: 'allowance', params: [account, farm.masterchef] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    console.log('sasasas', lpContractAddress)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    return {
      address: farm.masterchef,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(prvgMasterChefABI, calls)
  console.log('sasasasa', rawStakedBalances)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserNFTStakedBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    return {
      address: farm.masterchef,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(prvgMasterChefABI, calls)

  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return Number(stakedBalance[2]._hex)
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string) => {
  // const prv2Calls = []
  // const prvgCalls = []
  // const prvgObjects =[]
  // const prv2Objects =[]

  const prv2MasterChef = getMasterChef(prv2MasterChefABI,contracts.prvv2MasterChef[56]);
  const prvgMasterChef = getMasterChef(prvgMasterChefABI,contracts.prvgMasterChef[56]);


  const data = []
  // farmsConfig.map(async(farm) => {
  //   if (farm.isPRV2) {
  //     // prv2Calls.push({
  //     //   address: farm.masterchef,
  //     //   name: 'pendingPrv2',
  //     //   params: [farm.pid, account],
  //     // })
  //     // prv2Objects.push(farm)
  //     const resp = await prv2MasterChef.methods.pendingPrv2(farm.pid,account).call();
  //     data.push(  {
  //       pending: new BigNumber(resp[0]._hex),
  //       bonus: new BigNumber(resp[1]._hex),
  //       totalReward: new BigNumber(resp[2]._hex),
  //     })
  //   } else {
  //     const resp = await prvgMasterChef.methods.pendingPrvg(farm.pid,account).call();
  //     data.push(  {
  //       pending: new BigNumber(resp[0]._hex),
  //       bonus: new BigNumber(resp[1]._hex),
  //       totalReward: new BigNumber(resp[2]._hex),
  //     })
  //     // prvgCalls.push({
  //     //   address: farm.masterchef,
  //     //   name: 'pendingPrvg',
  //     //   params: [farm.pid, account],
  //     // })
  //     // prvgObjects.push(farm)
  //   }
  //   return null
  // })


  const  items = await Promise.all(farmsConfig.map(async (farm) => {
    if (farm.isPRV2) {
      // prv2Calls.push({
      //   address: farm.masterchef,
      //   name: 'pendingPrv2',
      //   params: [farm.pid, account],
      // })
      // prv2Objects.push(farm)
      const resp = await prv2MasterChef.methods.pendingPrv2(farm.pid,account).call();
      return {
        pending: new BigNumber(resp[0]),
        bonus: new BigNumber(resp[1]),
        totalReward: new BigNumber(resp[2]),
      }
    } 
    
    const resp = await prvgMasterChef.methods.pendingPrvg(farm.pid,account).call();
    
    
    console.log("Rssssso",resp)

    return {
       pending: new BigNumber(resp[0]),
       bonus: new BigNumber(resp[1]),
       totalReward: new BigNumber(resp[2]),
     }
}));


  console.log("sdasasasasaas",data)




  return items
}
