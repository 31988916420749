import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import prv2ABI from 'config/abi/prv2masterchef.json'
import prvgABI from 'config/abi/prvgmasterchef.json'
import lockedPRVGMasterChef from 'config/abi/lockedPRVGMasterChef.json'

import multicall from 'utils/multicall'
import farmsConfig from "config/constants/locked"
import { QuoteToken } from '../../config/constants/types'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

console.log("process.env.REACT_APP_CHAIN_ID",process.env.REACT_APP_CHAIN_ID)


const fetchLockedFarms = async () => {

  const data = await Promise.all(
    farmsConfig.map(async (farmConfig) => {
      const lpAdress = farmConfig.lpAddresses[CHAIN_ID]
      const calls = [
        // Balance of token in the LP contract
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // // Balance of quote token on LP contract
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'balanceOf',
          params: [lpAdress],
        },
        // // Balance of LP tokens in the master chef contract
        {
          address: farmConfig.isTokenOnly ? farmConfig.tokenAddresses[CHAIN_ID] : lpAdress,
          name: 'balanceOf',
          params: [farmConfig.masterchef],
        },
        // Total supply of LP tokens
        {
          address: lpAdress,
          name: 'totalSupply',
        },
        // Token decimals
        {
          address: farmConfig.tokenAddresses[CHAIN_ID],
          name: 'decimals',
        },
        // Quote token decimals
        {
          address: farmConfig.quoteTokenAdresses[CHAIN_ID],
          name: 'decimals',
        },
      ]



      const [tokenBalanceLP, quoteTokenBlanceLP, lpTokenBalanceMC, lpTotalSupply, tokenDecimals, quoteTokenDecimals] =
        await multicall(erc20, calls)



      let tokenAmount
      let lpTotalInQuoteToken
      let tokenPriceVsQuote
      if (farmConfig.isTokenOnly) {
        tokenAmount = new BigNumber(lpTokenBalanceMC).div(new BigNumber(10).pow(tokenDecimals))
        if (farmConfig.tokenSymbol === QuoteToken.BUSD && farmConfig.quoteTokenSymbol === QuoteToken.BUSD) {
          tokenPriceVsQuote = new BigNumber(1)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
        }
        lpTotalInQuoteToken = tokenAmount.times(tokenPriceVsQuote)
      } else {
        // Ratio in % a LP tokens that are in staking, vs the total number in circulation
        const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

        // Total value in staking in quote token value
        lpTotalInQuoteToken = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(18))
          .times(new BigNumber(2))
          .times(lpTokenRatio)

        // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
        tokenAmount = new BigNumber(tokenBalanceLP).div(new BigNumber(10).pow(tokenDecimals)).times(lpTokenRatio)
        const quoteTokenAmount = new BigNumber(quoteTokenBlanceLP)
          .div(new BigNumber(10).pow(quoteTokenDecimals))
          .times(lpTokenRatio)

        if (tokenAmount.comparedTo(0) > 0) {
          tokenPriceVsQuote = quoteTokenAmount.div(tokenAmount)
        } else {
          tokenPriceVsQuote = new BigNumber(quoteTokenBlanceLP).div(new BigNumber(tokenBalanceLP))
        }
      }

    
    

      let msABI = lockedPRVGMasterChef
      if(!farmConfig.isPRV2){
         msABI = lockedPRVGMasterChef

      }

      


      const [info, totalAllocPoint, prvPerBlock,expireTime] = await multicall(msABI, [
        {
          address: farmConfig.masterchef,
          name: 'poolInfo',
          params: [farmConfig.pid],
        },
        {
          address: farmConfig.masterchef,
          name: 'totalAllocPoint',
        },
        {
          address: farmConfig.masterchef,
          name: "prvPerBlock",

        },
        
        {
          address: farmConfig.masterchef,
          name: "expireTime",

        },


        

      ])




      

      const allocPoint = new BigNumber(info.allocPoint._hex)

      const _expireParsed = new BigNumber(expireTime).toNumber()
      console.log("expireee",_expireParsed,expireTime)

      const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))
      const isNFTStakingEnabled = info.isNFTStakingEnabled
      return {
        ...farmConfig,
        isNFTStakingEnabled,
        tokenAmount: tokenAmount.toJSON(),
        expireTime:_expireParsed,
        // quoteTokenAmount: quoteTokenAmount,
        lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
        tokenPriceVsQuote: tokenPriceVsQuote.toJSON(),
        poolWeight: poolWeight.toNumber(),
        multiplier: `${allocPoint.div(100).toString()}X`,
        depositFeeBP: info.depositFeeBP,
        prvPerBlock: new BigNumber(prvPerBlock).toNumber(),
      }
    }),
  )
  return data
}

export default fetchLockedFarms
