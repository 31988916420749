import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID)

const farms: FarmConfig[] = [
  {
    id:0,
    pid: 0,
    risk: 5,
    lpSymbol: 'PRVG',
    masterchef:contracts.prvgMasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0x50cdbfd0a01949fcc3647fbcb40c4ef34ad6475f",
    },
    tokenSymbol: 'PRVG',
    isTokenOnly: true,
    isPRV2:false,
    tokenAddresses: {
      97: contracts.prvg[chainId],
      56: "0x774ad968f8f3801739bcbc970f833c664d28511d",
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },




  {
    id:1,
    pid: 1,
    risk: 5,
    lpSymbol: 'PRV2-BNB',
    masterchef:contracts.prvgMasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0x21f141d2927d2bc639603c8bc922571b88ef812c",
    },
    tokenSymbol: 'PRV2',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prv2[chainId],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },




  {
    id:2,
    pid: 2,
    risk: 5,
    lpSymbol: 'PRV2-BUSD',
    masterchef:contracts.prvgMasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0xa53934f143b8b8aa8839e6dc01b56041d97eceba",
    },
    tokenSymbol: 'PRV2',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prv2[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    id:3,
    pid: 3,
    risk: 5,
    lpSymbol: 'PRVG-BNB',
    masterchef:contracts.prvgMasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0xba66c40e6b1684fde8d3ca3ab14ce0bf1352c97b",
    },
    tokenSymbol: 'PRVG',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prvg[chainId],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },



  {
    id:4,
    pid: 4,
    risk: 5,
    lpSymbol: 'PRVG-BUSD',
    masterchef:contracts.prvgMasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0x50cdbfd0a01949fcc3647fbcb40c4ef34ad6475f",
    },
    tokenSymbol: 'PRVG',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prvg[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },


  {
    id:5,
    pid: 6,
    risk: 5,
    lpSymbol: 'PRV2',
    masterchef:contracts.prvgMasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0xa53934f143b8b8aa8839e6dc01b56041d97eceba",
    },
    isTokenOnly:true,
    tokenSymbol: 'PRV2',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prv2[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },





  {
    id:6,
    pid: 5,
    risk: 5,
    masterchef:contracts.prvgMasterChef[chainId],
    lpSymbol: 'BNB-BUSD',
    lpAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // AUTO-BNB LP
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '0x1de7921C03AC76894e8c930654A12c60d4979217',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  


  {
    id:7,
    pid: 0,
    risk: 5,
    lpSymbol: 'PRV2',
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0xa53934f143b8b8aa8839e6dc01b56041d97eceba",
    },
    tokenSymbol: 'PRV2',
    isPRV2:true,
    masterchef:contracts.prvv2MasterChef[chainId],

    isTokenOnly:true,

    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prv2[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },




  {
    id:8,
    pid: 1,
    risk: 5,
    lpSymbol: 'PRV2-BNB',
    isPRV2:true,
    masterchef:contracts.prvv2MasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0x21f141d2927d2bc639603c8bc922571b88ef812c",
    },
    tokenSymbol: 'PRV2',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prv2[chainId],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },




  {
    id:9,
    pid: 2,
    risk: 5,
    lpSymbol: 'PRV2-BUSD',
    isPRV2:true,
    masterchef:contracts.prvv2MasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0xa53934f143b8b8aa8839e6dc01b56041d97eceba",
    },
    tokenSymbol: 'PRV2',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prv2[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  

  {
    id:10,
    pid: 3,
    risk: 5,
    lpSymbol: 'PRVG-BNB',
    isPRV2:true,
    masterchef:contracts.prvv2MasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0xba66c40e6b1684fde8d3ca3ab14ce0bf1352c97b",
    },
    tokenSymbol: 'PRVG',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prvg[chainId],
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },



  {
    id:11,
    pid: 4,
    risk: 5,
    lpSymbol: 'PRVG-BUSD',
    isPRV2:true,
    masterchef:contracts.prvv2MasterChef[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0x50cdbfd0a01949fcc3647fbcb40c4ef34ad6475f",
    },
    tokenSymbol: 'PRVG',
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prvg[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

]

export default farms
