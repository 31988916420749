import BigNumber from 'bignumber.js'
import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import useRefresh from 'hooks/useRefresh'

import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync,

  fetchLockedFarmsPublicDataAsync
   } from './actions'
import { State, Farm, Pool } from './types'
import { QuoteToken } from '../config/constants/types'



const ZERO = new BigNumber(0)

export const useFetchPublicData = () => {
  const dispatch = useDispatch()
  const { slowRefresh } = useRefresh()
  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync())
    dispatch(fetchLockedFarmsPublicDataAsync())
    // dispatch(fetchPoolsPublicDataAsync())
  }, [dispatch, slowRefresh])
}

// Farms

export const useFarms = (): Farm[] => {
  const farms = useSelector((state: State) => state.farms.data)
  return farms
}


export const useLockedFarms = (): Farm[] => {
  console.log("Sasasa",useSelector((state: State) => state))
  const farms = useSelector((state: State) => state.locked.data)
  return farms
}


export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}


export const useFarmFromId = (id): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.id === id))
  return farm
}
export const useLockedFarmUser = (id) => {
  const farm = useLockedFarmFromId(id)

  console.log("famr.userdata",farm.userData)
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData && farm.userData.earnings.totalReward ? new BigNumber(farm.userData.earnings.totalReward) : new BigNumber(0),
    stakedNFTID: farm.userData ? farm.userData.stakedNFTID : 0,
    baseEarnings: farm.userData && farm.userData.earnings.pending ? new BigNumber(farm.userData.earnings.pending) : new BigNumber(0),
    bonus: farm.userData && farm.userData.earnings.bonus ? new BigNumber(farm.userData.earnings.bonus) : new BigNumber(0),


  }
}


export const useLockedFarmFromId = (id): Farm => {
  const farm = useSelector((state: State) => state.locked.data.find((f) => f.id === id))
  return farm
}


export const useFarmFromSymbol = (lpSymbol: string): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return farm
}

export const useFarmUser = (id) => {
  const farm = useFarmFromId(id)

  console.log("famr.userdata",farm.userData)
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : new BigNumber(0),
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : new BigNumber(0),
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : new BigNumber(0),
    earnings: farm.userData && farm.userData.earnings.totalReward ? new BigNumber(farm.userData.earnings.totalReward) : new BigNumber(0),
    stakedNFTID: farm.userData ? farm.userData.stakedNFTID : 0,
    baseEarnings: farm.userData && farm.userData.earnings.pending ? new BigNumber(farm.userData.earnings.pending) : new BigNumber(0),
    bonus: farm.userData && farm.userData.earnings.bonus ? new BigNumber(farm.userData.earnings.bonus) : new BigNumber(0),


  }
}

// Pools

export const usePools = (account): Pool[] => {
  const { fastRefresh } = useRefresh()
  const dispatch = useDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(account))
    }
  }, [account, dispatch, fastRefresh])

  const pools = useSelector((state: State) => state.pools.data)
  return pools
}

export const usePoolFromPid = (sousId): Pool => {
  const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
  return pool
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  // return ZERO
  // const pid = 6 // BUSD-BNB LP
  // const farm = useFarmFromPid(pid)
  
  // console.log('BNB PRICE', new BigNumber(farm.tokenPriceVsQuote).toNumber())
  // return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO


  const id = 6 // BNB-BUSD LP
  const farm = useFarmFromId(id)
  console.log("BUDSS",new BigNumber(farm.tokenPriceVsQuote).toNumber())
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
  

}

export const usePriceEthBusd = (): BigNumber => {
  // const pid = 14 // ETH-BNB LP
  // const bnbPriceUSD = usePriceBnbBusd()
  // const farm = useFarmFromPid(pid)
  // const price =  farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO;

  return ZERO
}

export const usePriceCakeBusd = (): BigNumber => {
  // const pid = 0 // CAKE-BNB LP
  // const bnbPriceUSD = usePriceBnbBusd()
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
  // const pid = 1 // PRV-BUSD LP
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
  // return ZERO
  return ZERO

}

export const usePricePRV2Busd = (): BigNumber => {
  // const pid = 0 // CAKE-BNB LP
  // const bnbPriceUSD = usePriceBnbBusd()
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
  const pid = 2 // PRV-BUSD LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
  // return ZERO
  // return ZERO

}

export const usePricePRVGBusd = (): BigNumber => {
  // const pid = 0 // CAKE-BNB LP
  // const bnbPriceUSD = usePriceBnbBusd()
  // const farm = useFarmFromPid(pid)
  // return farm.tokenPriceVsQuote ? bnbPriceUSD.times(farm.tokenPriceVsQuote) : ZERO
  const pid = 0 // PRV-BUSD LP
  const farm = useFarmFromPid(pid)
  return farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : ZERO
  // return ZERO
  // return ZERO

}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  const cakePrice = usePriceCakeBusd()
  let value = new BigNumber(0)
  for (let i = 0; i < farms.length; i++) {
    const farm = farms[i]
    if (farm.lpTotalInQuoteToken) {
      let val
      if (farm.quoteTokenSymbol === QuoteToken.BNB) {
        val = bnbPrice.times(new BigNumber(farm.lpTotalInQuoteToken))
      } else if (farm.quoteTokenSymbol === QuoteToken.CAKE) {
        val = cakePrice.times(farm.lpTotalInQuoteToken)
      } else {
        val = farm.lpTotalInQuoteToken
      }
      value = value.plus(val)
    }
  }
  return value
}
