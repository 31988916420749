import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import lockedPRVGMasterChef from 'config/abi/lockedPRVGMasterChef.json'
import lockedPRV2MasterChef from 'config/abi/lockedPRV2MasterChef.json'

import multicall,{getMasterChef} from 'utils/multicall'
import farmsConfig from 'config/constants/locked'
import contracts from 'config/constants/contracts'

const CHAIN_ID = process.env.REACT_APP_CHAIN_ID

export const fetchFarmUserAllowances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    return { address: lpContractAddress, name: 'allowance', params: [account, farm.masterchef] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    const lpContractAddress = farm.isTokenOnly ? farm.tokenAddresses[CHAIN_ID] : farm.lpAddresses[CHAIN_ID]
    console.log('sasasas', lpContractAddress)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    return {
      address: farm.masterchef,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(lockedPRVGMasterChef, calls)
  console.log('sasasasa', rawStakedBalances)
  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return new BigNumber(stakedBalance[0]._hex).toJSON()
  })
  return parsedStakedBalances
}

export const fetchFarmUserNFTStakedBalances = async (account: string) => {
  const calls = farmsConfig.map((farm) => {
    return {
      address: farm.masterchef,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const rawStakedBalances = await multicall(lockedPRVGMasterChef, calls)

  const parsedStakedBalances = rawStakedBalances.map((stakedBalance) => {
    return Number(stakedBalance[0]._hex)
  })
  return parsedStakedBalances
}

export const fetchFarmUserEarnings = async (account: string) => {
 


  const  items = await Promise.all(farmsConfig.map(async (farm) => {
    let masterChef;
    if (farm.isPRV2) {
      masterChef =  getMasterChef(lockedPRV2MasterChef,farm.masterchef);
      const resp = await masterChef.methods.pendingPrv2(farm.pid,account).call();
      return {
        pending: new BigNumber(resp)
      }
    } 
    
    masterChef =  getMasterChef(lockedPRVGMasterChef,farm.masterchef);

    const resp = await masterChef.methods.pendingPrvg(farm.pid,account).call();
    
    
    console.log("Rssssso",resp)

    return {
       pending: new BigNumber(resp)
     }
}));


  console.log("sdasasasasaas",items)




  return items
}
