export default {
  cake: {
    56: '0x7762A14082Ab475C06D3868B385E46aE27017231',
    97: '0x599b470EaE66fcB961014B058E8Be260F143d52A',
  },
  masterChef: {
    56: '',
    97: '',
  },

  prvgMasterChef:{
    56:"0xdAcb4A770c291F00A88F40e6E8efd49BfdF78198",
    97:"0x43661cbd84eF9694DE5A12E6B7234EE8B7a38B30"
  },

  prvv2MasterChef:{
    56:"0x871B25aB88FdE0f2Ef677c0e0912Aa730c881A2C",
    97:"0x4CbFF77E0F959E3C612E082F6E1e0051ca5D7442"
  },

  prvNFT: {
    56: '0x0f655fBC4C7B72938d7a2B62c7d22c8f00aD8E15',
    97: '0x8fb5234b3F4Fe45cc6a9eF799d6184dAc44cd953',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0xA73D61379627dB94f968C7bb4BD7fF5c07723Ec2',
  },
  lottery: {
    56: '0x938062218d298A5a6B8c44a723A4A7177D2761cA',
    97: '0x78f97C5dF7FC51E68DeA698DB7EF1DA96681f880',
  },
  lotteryNFT: {
    56: '0xDA8B8Fc2aCC81168401F745cBACeC461c2889De3',
    97: '0x8604B06459c3e7200355DcE354921E3012cDD43F',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },

  multiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x599b470EaE66fcB961014B058E8Be260F143d52A',
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  referral: {
    56: '0xa8d9F2Df2b642136Be09fE6D8d5497bbF7350BaB',
    97: '0x906Db7ec8D5CCD3A2879eFD63834EE11bfEdd503',
  },
  forest: {
    56: '0x6DC799bc0CcC3aA49c2565C435131842D40e235a',
    97: '0x67CA3eB4b315Ee45d5F08a22B8425dC5b6a53626', // not tested
  },

  prvgLockedStaking: {
    56: '0x2973ab26dcbC8253933E5b7A14aC8Aa7cDF5C628',
    97: '0xE3a41B777978E3a04285eB5a5a9388c10061e5EA', // not tested
  },
  prv2LockedStaking: {
    56: '0x494767Bf1568c46664AFf9874e8077F4e0E8b55e',
    97: '0xE3a41B777978E3a04285eB5a5a9388c10061e5EA', // not tested
  },

  prv2ToPrv2LockedStaking:{
    56:"0x5D32824f02fd763eE5442ce3326dAc92843d014d",
    97:"0x133cB7ED9707eFC79EA3182450a2Bbc0ccfd614B"
  },

  busdPresale: {
    56: '0x836e9321aa6903b7dDB006aA2c61Ae5428912bD8',
    97: '0x9938260ADAf068140F01C044d2EC9feDE938Cb7B', // not tested
  },
  prvPresale: {
    56: '0x588546AF5C33c893445bf6D4d175DfbeF802261D',
    97: '0x588546AF5C33c893445bf6D4d175DfbeF802261D', // not tested
  },


  prv2:{
    56: '0xee10936e1f6b49945b17efeb106cc6992fe5ae47',
    97: '0x38cE92C20deE56750A7595EF80d4C3D72Af0Ac58', // not tested
  },
  prvg:{
    56: '0x774ad968f8f3801739bcbc970f833c664d28511d',
    97: '0x2cffeeaf9ef259a058386c37dd92a623d415100f', // not tested
  },

}
