import addresses from 'config/constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID

export const getCakeAddress = () => {
  return addresses.cake[chainId]
}

export const getBUSDAddress = () => {
  return addresses.busd[chainId]
}


export const getPREPRV2Address = () => {
  return "0x24de9b7ba401adb1abb297af42992228504a8a3a"
}

export const getPREPRVGAddress = () => {
  return "0xd1d9acce854b4addd24fd441b1e202415dfb02ae"
}

export const getPRV2Address = () => {
  return addresses.prv2[chainId]
}

export const getPRVGAddress = () => {
  return addresses.prvg[chainId]
}

export const getPresaleAddress = (isBUSD) => {
  return isBUSD?addresses.busdPresale[chainId]:addresses.prvPresale[chainId]
}


export const getNFTAddress = () => {
  return addresses.prvNFT[chainId]
}
export const getMasterChefAddress = () => {
  return addresses.masterChef[chainId]
}

export const getMasterChefPRV2Address = () => {
  return addresses.prvv2MasterChef[chainId]
}



export const getlockedMasterChefPRV2Address = () => {
  return addresses.prv2LockedStaking[chainId]
}

export const getlockedMasterChefPRVGAddress = () => {
  return addresses.prvgLockedStaking[chainId]
}


export const getMasterChefPRVGAddress = () => {
  return addresses.prvgMasterChef[chainId]
}

export const getPRV2MasterChefAddress = () => {
  return addresses.prvv2MasterChef[chainId]
}
export const getPRVGMasterChefAddress = () => {
  return addresses.prvgMasterChef[chainId]
}
export const getMulticallAddress = () => {
  return addresses.multiCall[chainId]
}
export const getWbnbAddress = () => {
  return addresses.wbnb[chainId]
}
export const getLotteryAddress = () => {
  return addresses.lottery[chainId]
}
export const getLotteryTicketAddress = () => {
  return addresses.lotteryNFT[chainId]
}

export const getForestAddress = () => {
  return addresses.forest[chainId]
}

export const getReferralAddress = () => {
  return addresses.referral[chainId]
}
