/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/locked'
import fetchLockedFarms from './fetchFarms'
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
  fetchFarmUserNFTStakedBalances
} from './fetchFarmUser'
import { FarmsState, Farm } from '../types'

const initialState: FarmsState = { data: [...farmsConfig] }

export const farmsSlice = createSlice({
  name: 'Locked',
  initialState,
  reducers: {
    setLockedFarmsPublicData: (state, action) => {
      const liveFarmsData: Farm[] = action.payload
      state.data = state.data.map((farm) => {
        const liveFarmData = liveFarmsData.find((f) => f.id === farm.id)
        return { ...farm, ...liveFarmData }
      })
    },
    setLockedFarmUserData: (state, action) => {
      const { arrayOfUserDataObjects } = action.payload
      arrayOfUserDataObjects.forEach((userDataEl) => {
        const { index } = userDataEl
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
    },
  },
})

// Actions
export const { setLockedFarmsPublicData, setLockedFarmUserData } = farmsSlice.actions

// Thunks
export const fetchLockedFarmsPublicDataAsync = () => async (dispatch) => {

  const farms = await fetchLockedFarms()
  dispatch(setLockedFarmsPublicData(farms))
}
export const fetchLockedFarmUserDataAsync = (account) => async (dispatch) => {


  console.log("fetchLockedFarmUserDataAsync")

  const userFarmAllowances = await fetchFarmUserAllowances(account)

  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account)

  const userStakedBalances = await fetchFarmUserStakedBalances(account)


  const userFarmEarnings = await fetchFarmUserEarnings(account)
  // console.log("fetchFarmUserDataAsync4",userFarmEarnings)

  const arrayOfUserDataObjects = userFarmAllowances.map((farmAllowance, index) => {
    return {
      index,
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index],
    }
  })

  dispatch(setLockedFarmUserData({ arrayOfUserDataObjects }))
}

export default farmsSlice.reducer
