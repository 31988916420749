import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID)

const farms: FarmConfig[] = [
  {
    id:0,
    pid: 0,
    risk: 5,
    lpSymbol: 'PRVG',
    masterchef:contracts.prvgLockedStaking[chainId],
    lpAddresses: {
      97: contracts.prvg[chainId],
      56: "0x50cdbfd0a01949fcc3647fbcb40c4ef34ad6475f",
    },
    tokenSymbol: 'PRVG',
    isTokenOnly: true,
    isPRV2:false,
    tokenAddresses: {
      97: contracts.prvg[chainId],
      56: contracts.prvg[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



  {
    id:1,
    pid: 0,
    risk: 5,
    lpSymbol: 'PRV2',
    masterchef:contracts.prv2LockedStaking[chainId],
    lpAddresses: {
      97: contracts.prv2[chainId],
      56: "0xa53934f143b8b8aa8839e6dc01b56041d97eceba",
    },
    tokenSymbol: 'PRV2',
    isTokenOnly: true,
    isPRV2:true,
    tokenAddresses: {
      97: contracts.prv2[chainId],
      56: contracts.prv2[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },


  {
    id:2,
    pid: 0,
    risk: 5,
    lpSymbol: 'PRVG',
    masterchef:contracts.prv2ToPrv2LockedStaking[chainId],
    lpAddresses: {
      97: contracts.prv2[chainId],
      56: "0x50cdbfd0a01949fcc3647fbcb40c4ef34ad6475f",
    },
    tokenSymbol: 'PRVG',
    isTokenOnly: true,
    isPRV2:true,
    tokenAddresses: {
      97: contracts.prvg[chainId],
      56: contracts.prvg[chainId],
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },



]

export default farms
