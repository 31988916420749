import { Nft } from './types'

export const RABBIT_MINTING_FARM_ADDRESS = '0x7c8b60d2b859a38c8B9b5B6CB4565485cb637c7a'
export const PANCAKE_RABBITS_ADDRESS = '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07'

const Nfts: Nft[] = [
  {
    name: 'Privacy Silver',
    description: 'These bunnies love nothing more than swapping pancakes. Especially on BSC.',
    originalImage: '/images/nfts/prv-silver.jpeg',
    previewImage: 'prv-silver.jpeg',
    blurImage: 'swapsies-blur.png',
    sortOrder: 999,
    bunnyId: 0,
    price:0.25
  },
  {
    name: 'Privacy Gold',
    description: "It's raining syrup on this bunny, but he doesn't seem to mind. Can you blame him?",
    originalImage: '/images/nfts/prv-gold.jpeg',
    previewImage: 'prv-gold.jpeg',
    blurImage: 'drizzle-blur.png',
    sortOrder: 999,
    bunnyId: 1,
    price:0.50

  },
  {
    name: 'Privacy Diamond',
    description: "These bunnies like their pancakes with blueberries. What's your favorite topping?",
    originalImage: '/images/nfts/prv-diamond.jpeg',
    previewImage: 'prv-diamond.jpeg',
    blurImage: 'blueberries-blur.png',
    sortOrder: 999,
    bunnyId: 2,
    price:1 

  },
  
]

export default Nfts
